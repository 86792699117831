<template>
  <div class="home" :style="homeStyle">
    <div class="index-container" :style="containerStyle" >
      <div class="download" ref="download" @click="changeHandle">
        <a-carousel :beforeChange="onCarouselBeforeChange" easing="ease" :afterChange="onCarouselChange" autoplay
                    class="home-carousel"
        >
          <div v-if="bannerCover" style="cursor:pointer" class="home-card">
          </div>
          <div class="home-card" style="margin-left: 0;text-align: center">
            <div class="container">
              <img class="home-card-title" src="@/assets/title.png">
              <div class="home-card-btn" @click="$router.push('/videoResource')">
                进入网络学习
              </div>
              <div class="home-card-btn-extra" @click="download"><span>下载客户端</span></div>
            </div>
          </div>
        </a-carousel>
      </div>
      <!-- <Footer :style="{ background: ['transparent' ][current] }" class="footer" /> -->
      <Footer
          :style="{ background: ['rgba(255, 255, 255, 0.9)','rgba(255, 255, 255, 0.9)' ][current] ,'padding-top':'24px'}"
          class="footer"/>

      <!-- 弹出许可证框 -->
      <div class="popup" v-if="ispopup" @click="closePic">
        <div class="popup_img">
          <img src="../assets/Fill 3.png" alt class="close"/>
          <img @click.stop="clicks" src="../assets/pic-1.jpg" alt/>
        </div>
      </div>
      <div class="popup" v-if="isWebPopup" @click="closePic">
        <div class="popup_img">
          <img src="../assets/Fill 3.png" alt class="close"/>
          <img @click.stop="clicks" src="../assets/pic-2.jpg" alt/>
        </div>
      </div>
      <!-- 更多版本 -->
      <div class="moreLoad" v-if="isMoreLoad">
        <div class="moreLoad_version">
          <div class="moreLoad_version_close" @click="moreVersion">
            <img src="../assets/Close.png" alt=""/>
          </div>
          <div class="moreLoad_version_title">更多版本</div>
          <div v-for="(item, index) in versionList" :key="index" class="versionList" @click="downVersion(index)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- <div class="attentionBox">
        <div v-show="isShow" class="attentionPic">
          <img src="../assets/wecom-temp-441ae10872831861cf94fd4d30e4514a.png" alt="">
          <span></span>
        </div>
        <div @mouseenter="isShow = true" @mouseleave="isShow = false" class="text">
          <span>
            扫码关注
          </span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer"
import {completed, getZjerUser, zjerLogin} from "../api/user"
import {mapActions, mapMutations} from "vuex"
import debounce from 'lodash.debounce'
import base from '@/api/base'
import bg2 from '@/assets/home-bg.jpg'
import Cookies from "js-cookie";
import {getBanner} from "@/api/prize";

export default {
  name: "Content",
  components: {
    Footer
  },
  data() {
    return {
      current: 0,
      agent: "",
      isMac: "",
      ispopup: false,
      isWebPopup: false,
      isWindows: true,
      isMoreLoad: false,
      isShow: false,
      versionList: [
        {
          name: "Windows64版下载"
        },
        {
          name: "Windows32版下载"
        }
        // {
        //   name: "Mac版下载",
        // },
      ],
      setHeightDebounce: debounce(this.setBannerHeight, 200),
      bannerCover: '',
    }
  },

  methods: {
    ...mapMutations(["setZjerParam"]),
    ...mapActions(['toClassServer']),
    clicks() {
    },
    changeHandle() {
      if (this.current === 0 && this.bannerCover) {
        this.$router.push('/prizeV1')
      }
    },
    onCarouselBeforeChange(form, to) {
      this.current = to
    },
    onCarouselChange(current) {
      // this.current = current
    },
    moreVersion() {
      this.isMoreLoad = this.isMoreLoad == false ? true : false
    },
    async getBannerHandle() {
      const res = await getBanner({terminalType: 0, position: 0})
      if (res.data.length > 0) {
        this.bannerCover = res.data[0].bannerCover
      } else {
        this.bannerCover = ''
      }
    },
    download() {
      // if(this.agent) {
      //   this.$axios.get("/api/v1/pub/clientVersion/new"
      //   ).then(res=>{
      //     console.log(res.data)
      //     window.location.href = res.data.winUrl
      //   })
      // }
      // else{
      //   this.$axios.get("/api/v1/pub/clientVersion/new"
      //   ).then(res=>{
      //     console.log(res.data)
      //     window.location.href = res.data.macUrl
      //   })
      // }
      if (this.agent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        this.$message.error('请使用电脑下载')
      } else {
        if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
          this.$axios.get(`${base}/api/v1/pub/clientVersion/new`).then(res => {
            window.location.href = res.data.win32Url
          })
        } else if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
          this.$axios.get(`${base}/api/v1/pub/clientVersion/new`).then(res => {
            window.location.href = res.data.winUrl
          })
        } else if (this.isMac) {
          this.$axios.get(`${base}/api/v1/pub/clientVersion/new`).then(res => {
            // window.location.href = res.data.macUrl;
            window.location.href = res.data.winUrl
          })
        } else {
          this.$axios.get(`${base}/api/v1/pub/clientVersion/new`).then(res => {
            // console.log(res.data);
            window.location.href = res.data.winUrl
          })
        }
      }
    },
    OSnow() {
      this.agent = navigator.userAgent.toLowerCase()
      this.isMac = /macintosh|mac os x/i.test(navigator.userAgent)

      //判断当前电脑版本
      if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
        // console.log("这是windows32位系统");
        this.isWindows = true
      }
      if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
        // console.log("这是windows64位系统");
        this.isWindows = true
      }
      if (this.isMac) {
        this.isWindows = false
      }
    },

    //选择下载版本
    downVersion(index) {
      console.log(index)
      if (index == 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          window.location.href = res.data.winUrl
        })
      } else if (index == 1) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          window.location.href = res.data.win32Url
        })
      } else {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          window.location.href = res.data.winUrl
          // window.location.href = res.data.macUrl;
        })
      }
    },

    closePic() {
      this.ispopup = false
      this.isWebPopup = false
    },

    toOpen(data) {
      this.ispopup = data
    },
    toWebOpen(data) {
      this.isWebPopup = data
    },
    setBannerHeight() {
      if (this.$refs.download) {
        const width = this.$refs.download?.getBoundingClientRect().width
        const height = width * 0.32
        this.$refs.download.style = `height: ${height}px;`
      }
    },
    open(url) {
      const that = this
      if (!localStorage.user) {
        this.$confirm({
          title: '登录提示',
          content: h => <div>请登录后参与此活动</div>,
          okText: '立即登录',
          cancelText: '暂不参与',
          onOk() {
            that.$router.push("/loginRegistration?loginStep=1")
          },
          onCancel() {
          },
          class: 'test',
        });
        return false
      }
      if (window.location.origin.indexOf("xht.com") > -1) {
        //测试
        url = 'http://zjeav.xht.com/#/prize'
      } else if (window.location.origin.indexOf("pre-zjeav.zjeav.com") > -1) {
        //预发
        url = 'http://pre-zjeav.zjeav.com/#/prize'
      } else if (window.location.origin.indexOf("zjeav.com") > -1) {
        //线上
        url = '//www.zjeav.com/#/prize'
      } else if (window.location.origin.indexOf("localhost") > -1) {
        // 开发
        url = 'http://localhost:8080/#/prize'
      }
      window.open(url)

    },
    ismobile() {
      let mobileArry = ['iPhone', 'iPad', 'Android', 'Windows Phone', 'BB10; Touch', 'BB10; Touch', 'PlayBook', 'Nokia'];
      let ua = navigator.userAgent;
      let res = mobileArry.filter(function (arr) {
        return ua.indexOf(arr) > 0;
      });
      return res.length > 0;
    }
  },
  mounted() {
    new Image().src = bg2
    // this.setBannerHeight()
    this.getBannerHandle()
    this.OSnow()
    window.addEventListener('resize', this.setHeightDebounce)
    if (window.location.href.indexOf("client_id") > 0) {
      const personId = window.location.href.split("personId=")[1].split("#")[0]
      const oauthCode = window.location.href.split("oauthCode=")[1].split("&")[0]
      getZjerUser({personId}).then(res => {
        if (res.data.teacherFlag && res.data.bindFlag) {
          zjerLogin({
            personId,
            oauthCode
          }).then(res => {
            if (res.status === 200) {
              localStorage.user = JSON.stringify(res.data)
              // 判断是否绑定手机号
              completed(res.data.userId).then((res1) => {
                if (this.ismobile()) {
                  Cookies.set('commonName', localStorage.user, {expires: 7, domain: 'zjeav.com'})
                  if (res1.data) {
                    location.href = 'https://zjy.zjeav.com/#/mobileH5/home' //生产环境
                  } else {
                    // 完善信息
                    location.href = `https://zjy.zjeav.com/#/mobileH5/improveInformation?type=${res.data.roles[0]}`//生产环境
                  }
                } else {
                  if (res1.data) {
                    const redirectUrl = sessionStorage.getItem('redirectUrl')
                    if (redirectUrl) {
                      this.toClassServer({replace: true, redirectUrl})
                    } else {
                      window.location.href = window.location.origin
                    }
                  } else {
                    // 完善信息
                    // http://172.27.71.236:8080/#/loginRegistration?loginStep=4&role='teacher
                    this.$router.push(`/loginRegistration?loginStep=4&role=${res.data.roles[0]}`)
                  }

                }
              })
              // if(this.ismobile()){
              // 	Cookies.set('commonName', localStorage.user, { expires: 3, domain: 'zjeav.com' })
              // 	location.href = 'https://zjy.zjeav.com/#/mobileH5/home' //生产环境
              // }else {
              // 	window.location.href = window.location.origin
              // }
            }
          })
        } else if (!res.data.teacherFlag) {
          this.$message.error("请输入教师账号")
        } else {
          this.setZjerParam({personId, oauthCode})
          // this.$router.push("/loginRegistration?loginStep=0")
          if (this.ismobile()) {
            Cookies.set('commonName', localStorage.user, {expires: 7, domain: 'zjeav.com'})
            location.href = 'https://zjy.zjeav.com/#/mobileH5/bindChoice?oauthCode=' + `${oauthCode}&personId=${personId}` //生产环境
          } else {
            this.$router.push({path: '/loginRegistration', query: {loginStep: '7', isTeacher: res.data.teacherFlag}})
          }
        }
      })
    }
  },
  computed: {
    containerStyle() {
      if (this.bannerCover) {
        return `background-image: url(${[this.bannerCover, bg2][this.current]});`
      } else {
        return `background-image: url(${[bg2][this.current]});`
      }
    },
    homeStyle() {
      return `background-image: linear-gradient(to top,#fff 274px,#3D3D66 274px);`
    }
  }
}
</script>

<style lang="less" scoped>
.index-container {
  // transition: background-image .1s;
  will-change: background-image;
  background-position: 0 -20px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  height: 100vh;
  margin-top: -72px;
  display: flex;
  flex-direction: column;

  .home-carousel {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    flex: 1;
    overflow: hidden;

    /deep/ .slick-dots {
      text-align: center;
      bottom: 28px;

      li {
        overflow: hidden;
        opacity: 0.3;
        border-radius: 3px;

        &.slick-active {
          opacity: 1;
        }

        button {
          height: 6px;
        }
      }
    }
  }

  .home-card {
    height: 100%;
    padding-top: 72px;

    .container {
      position: relative;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }

    &-title {
      margin: auto;
      width: 389px;
      margin-bottom: 33px;
    }

    &-info {
      font-size: 22px;
      opacity: 0.8;
      color: #FFFFFF;
      margin: 28px 0;
      letter-spacing: 2px;
      font-family: PingFangSC-Regular;
      line-height: 38px;
    }

    &-btn {
      cursor: pointer;
      padding: 0 24px;
      background: linear-gradient(90deg, #FB8056 0%, #F6492B 100%);
      // box-shadow: 0px 4px 16px -4px rgba(246, 73, 43, 0.3);
      border-radius: 30px;
      display: inline-flex;
      align-items: center;

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &-extra {
        font-weight: 400;
        line-height: 50px;
        font-size: 14px;
        color: #FFFFFF;

        span {
          cursor: pointer;

        }
      }

      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 48px;
    }
  }

  .attentionBox {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    bottom: 72px;

    .attentionPic {
      position: relative;
      height: 116px;
      width: 116px;
      background-color: rgb(247, 50, 16);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      img {
        width: 80%;
        height: 80%;
      }

      span {
        position: absolute;
        right: -6px;
        border-top: 6px solid rgb(247, 50, 16);
        border-right: 6px solid rgb(247, 50, 16);
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        transform: rotate(45deg)
      }
    }

    .text {
      height: 115px;
      margin-left: 15px;
      background-color: rgb(247, 50, 16);
      border-radius: 5px 0 0 5px;
      padding: 8px;
      width: 41px;
      cursor: pointer;

      span {
        display: block;
        color: #fff;
        word-break: break-all;
        overflow-wrap: break-word;
        width: 14px;
        padding: 0 6px;
      }
    }
  }
}

.button-box {
  position: relative;
}

.download {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  &_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cur_button {
    z-index: 10;
    position: absolute;
    height: 44px;
    width: 170px;
    top: 66%;
    cursor: pointer;
    left: 16%;
  }

  &_button {
    background: #f6492b;
    border-radius: 7px;
    height: 54px !important;
    width: 320px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
    position: absolute;
    top: 66.7%;
    left: 59%;
    cursor: pointer;
    transition: all 0.2s ease-in;

    .content {
      background: #f6492b;
      border-radius: 5px;
    }

    .content:hover {
      transform: translateY(-2px);
      box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &_moreVersion {
    color: #ffffff;
    position: absolute;
    margin-top: 10px;
    font-size: 14px;
    padding-bottom: 1px;
    cursor: pointer;
    border-bottom: 1px solid #ffffff;
    font-family: PingFangSC-Regular;
    height: 20px !important;
  }

  &_icon {
    margin-right: 5px;
  }

  &_message {
    position: absolute;
    // left: 87px;
    // 临时改的
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    left: 70px;
    line-height: 54px;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    &_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .close {
        position: absolute;
        cursor: pointer;
        top: 14px;
        right: 14px;
      }
    }
  }
}

.moreLoad {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  &_version {
    width: 432px;
    height: 240px;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &_close {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    &_title {
      margin: 32px auto 24px;
      width: 330px;
      height: 24px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      line-height: 24px;
    }

    .versionList {
      margin: 0 auto 12px;
      width: 290px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      line-height: 32px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #666666;
      cursor: pointer;

      &:hover {
        background-color: #f6492b;
        border-color: #f6492b;
        color: #fff;
      }
    }
  }
}

/deep/ .ant-carousel {
  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }
  }

  .slick-dots {
    //bottom: 80px !important;
  }
}
</style>
