<template>
    <div class="newMaterial">
        <div class="newMaterial-box">
     <MyBanner :position="1" class="newMaterial-box-banner"></MyBanner>
            <div class="newMaterial-box-content">
                <div>
                    <div class="newMaterial-box-content_title">浙江省音像教材网络下载</div>
                    <div class="newMaterial-box-content_detail">适用于小学、初中、高中多学科让您轻松、高效掌握学习内容。</div>
                    <div class="newMaterial-box-content_download" @click="download">
                        <img src="../assets/newMaterial/download.svg" alt="">
                        <span>Windows版客户端下载</span>
                    </div>
                    <div class="newMaterial-box-content_detail" style="cursor: pointer;" @click="moreVersion">
                        更多版本
                    </div>
                </div>
                <div class="newMaterial-box_Imac">
                    <img src="../assets/newMaterial/iMac.png" alt="">
                </div>
            </div>
            <div class="newMaterial-box-detail">
                <img v-for="(item, index) in imgList" :key="index" :src="item" alt="">
            </div>
        </div>
        <div class="moreLoad" v-if="isMoreLoad">
            <div class="moreLoad_version">
                <div class="moreLoad_version_close" @click="moreVersion">
                    <img src="../assets/Close.png" alt="">
                </div>
                <div class="moreLoad_version_title">版本选择</div>
                <div v-for="(item, index) in versionList" :key="index" class="versionList" @click="downVersion(index)">
                    {{ item.name }}</div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import MyBanner from '../components/MyBanner.vue'
import Footer from '@/components/Footer'
export default {
    name: "newMaterial",
    components: {
        Footer,
        MyBanner
    },
    data() {
        return {
            isWindows: true,
            isMoreLoad: false,
            versionList: [
                {
                    name: "Windows64版下载"
                },
                {
                    name: "Windows32版下载"
                }
            ],
            imgList: [
                require('../assets/newMaterial/content1.png'),
                require('../assets/newMaterial/content2.png'),
                require('../assets/newMaterial/content3.png')
            ]
        }
    },
    methods: {
        OSnow() {
            this.agent = navigator.userAgent.toLowerCase()
            this.isMac = /macintosh|mac os x/i.test(navigator.userAgent)

            if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
                // console.log("这是windows32位系统")
                this.isWindows = true
            }
            if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
                console.log("这是windows64位系统")
                this.isWindows = true
            }
            if (this.isMac) {
                this.isWindows = false
            }
        },
        clicks() { },
        moreVersion() {
            this.isMoreLoad = this.isMoreLoad == false ? true : false
        },
        download() {
            if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    console.log(res.data)
                    window.location.href = res.data.win32Url
                })
            } else if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    console.log(res.data)
                    window.location.href = res.data.winUrl
                })
            } else if (this.isMac) {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    window.location.href = res.data.winUrl
                })
            } else {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    // console.log(res.data);
                    window.location.href = res.data.winUrl
                })
            }
        },
        downVersion(index) {
            if (index == 0) {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    // console.log(res.data);
                    window.location.href = res.data.winUrl
                })
            } else if (index == 1) {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    // console.log(res.data);
                    window.location.href = res.data.win32Url
                })
            } else {
                this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
                    // console.log(res.data);
                    window.location.href = res.data.winUrl
                })
            }
        },
    },
    mounted() {
        this.OSnow()
    }
}
</script>

<style lang="less" scoped>
.newMaterial {
    width: 100%;

    &-box {
        position: relative;
        width: 100%;
        height: 972px;
        background-image: url('../assets/newMaterial/download_bg.png');
        background-size: cover;
      &-banner {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
        &_Imac {
            width: 500px;
            height: 500px;
            z-index: 1;
            margin-top: -175px;

            >img {
                width: 500px;
                height: 500px;
            }
        }

        overflow: hidden;
        margin:0 auto;

        &-content {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 150px;
            margin-top: 290px;
            &_title {
                font-size: 36px;
                font-weight: 600;
                color: #333333;
            }

            &_detail {
                margin-top: 16px;
            }

            &_download {
                cursor: pointer;
                width: 264px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 48px;
                padding: 0 24px;
                background: linear-gradient(90deg, #FB8056 0%, #F6492B 100%);
                box-shadow: 0px 4px 16px -4px rgba(246, 73, 43, 0.3);
                border-radius: 30px;
                color: #FFFFFF;
                font-size: 18px;
            }
        }

        &-detail {
            position: absolute;
            display: flex;
            justify-content: space-evenly;
            bottom: 0px;
            width: 100%;
            height: 400px;
            padding-top: 46px;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            border-radius: 160px 0px 0px 0px;

            >img {
                width: 232px;
                height: 294px;
            }
        }
    }
}

.moreLoad {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1200px;
    height: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.6);

    &_version {
        width: 432px;
        height: 240px;
        border-radius: 4px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &_close {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
        }

        &_title {
            margin: 32px auto 24px;
            width: 330px;
            height: 24px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
            line-height: 24px;
        }

        .versionList {
            margin: 0 auto 12px;
            width: 290px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            line-height: 32px;
            text-align: center;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #666666;
            cursor: pointer;

            &:hover {
                background-color: #f6492b;
                border-color: #f6492b;
                color: #fff;
            }
        }
    }
}
</style>
